<script>
import axios from 'axios';

import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "id", label: "" },
        { key: "date", label: "Data", sortable: true, },
        { key: "payees", label: "Favorecidos", sortable: true, },
        { key: "payable", label: "À Pagar", sortable: true, },
        { key: "status", label: "Status", sortable: true, },
        { key: "url", label: "Arquivo", },
      ],
      items: null,

      sortBy: "date",
      sortDesc: false,
      currentPage: "1",
      perPage: "10",

      calcel: {
        modal: false,
        id: null,
      },
      del: {
        modal: false,
        id: null,
      },
    };
  },
  methods: {
    getPagamentos() {
      api.get("pagamentos").then((response) => {
        if (response.data.status == "success") {
          this.total = response.data.total;
          this.items = response.data.list;
        }
      })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDelete(id) {
      this.del.modal = true;
      this.del.id = id;
    },
    delFile() {
      if (this.del.id) {
        this.del.modal = false;

        api.delete("pagamentos/" + this.del.id).then((response) => {
          if (response.data.status == "success") {
            this.getPagamentos();
            this.$toast.success('O arquivo foi excluído com sucesso.');
          }
        })
      }
    },
    downloadFile(id) {
      axios({
        url: process.env.VUE_APP_URL_API + "pagamentos/" + id + "/download",
        method: 'GET',
        responseType: 'blob',
        headers: {
          "x-auth-token": localStorage.token,
        }
      }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', id + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      }).catch(error => {
        if (error) {
          this.$toast.error('Erro durante o download do arquivo.');
        }
      });
    }
  },
  mounted() {
    this.getPagamentos();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Folha de Pagamento</h3>
        <p>Gerencie suas folhas de pagamentos.</p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item to="pagamentos/importar">Importar</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card">
      <div class="card-body min-vh-50">
        <div v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="text-center">
          Nenhuma folha de pagamento encontrada.
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(id)="row">
              <b-dropdown variant="more">
                <template #button-content>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item :to="'/pagamentos/importar/' + row.item.id"><i class="bx bx-pencil"></i>
                  ver folha de pagamento</b-dropdown-item>
                <b-dropdown-item @click="showDelete(row.item.id)"><i class="bx bx-trash"></i> excluir</b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(payable)="row">
              {{ row.item.payable | currency }}
            </template>
            <template #cell(url)="row">
              <button class="btn btn-link" v-on:click="downloadFile(row.item.id)"><i class="bx bx-download font-size-20 align-middle"></i></button>
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status == 'pending'" class="bg-warning rounded px-1 font-size-1 text-uppercase text-white">Pendente</span>
              <span v-else class="bg-success rounded px-1 font-size-1 text-uppercase text-white">Processado</span>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir arquivo</h4>
        <p>Confirma exclusão do arquivo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delFile">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>